//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TermsAndConditionsText from '@/components/Legal/TermsAndConditions'
import PrivacyPolicyText from '@/components/Legal/PrivacyPolicy'
export default {
    name: 'Registration',
    components: {
        TermsAndConditionsText,
        PrivacyPolicyText
    },
    data() {
        return {
            first_name: null,
            last_name: null,
            email_address: null,
            password: null,
            confirmPassword: null,
            accept: false,
            isRegistering: false,
            openTermsAndConditions: false,
            openPrivacyPolicy: false
        }
    },
    metaInfo:{
      title:'Register |'
    },
    methods: {
        onSubmit() {
            if (this.password != this.confirmPassword) {
                this.$q.notify({
                    color: 'warning',
                    message: 'The password and the repeat password do not match. Please try again.'
                });
                return;
            }
            let context = this;
            this.isRegistering = true;
            this.$http({
                method: 'post',
                url: 'registration/save',
                data: {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email_address: this.email_address,
                    password: this.password
                  }
                })
                .then(function (response) {
                    context.isRegistering = false;
                    if (response.data['status'] == 'save_successful') {
                        context.$router.push({
                                name: 'Activation',
                                params: { 
                                    email: response.data['body']['email_address'],
                                    activation_key: response.data['body']['activation_key']
                                }
                            }
                        );
                    }
                    if (response.data['status']['status'] == 'email_unavailable') {
                        context.$q.notify({
                            message: 'The email address you entered is not available.',
                            color: 'negative' 
                        });
                    }
                    if (response.data['status']['status'] == 'password_short') {
                        context.$q.notify({
                            message: 'The password is too short.',
                            color: 'warning' 
                        });
                    }
                    
                })
                .catch(function () {
                    context.isRegistering = false;
                });
        },
        onReset() {
            this.first_name = '';
            this.last_name = '';
            this.email_address = '';
            this.password = '';
        }
    },
    created(){
        if (this.$store.state.isLoggedIn) {
            this.$router.push({name: 'Home'});
            return;
        }
    }
}
